/* DashboardLayout.module.css */

/* Root Layout Styles */
.dashboardLayout {
  min-height: 100vh;
  background-color: var(--primary-bg);
  position: relative;
}


/* Content Wrapper */
.dashboardContentWrapper {
  margin-left: 280px; /* Match sidebar width */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
}

/* When sidebar is collapsed */
.sidebarCollapsed + .dashboardContentWrapper {
  margin-left: 72px;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .dashboardContentWrapper {
    margin-left: 0;
  }
}

/* Main Content Area */
.mainContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Messages Container */
.messagesContainer {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: calc(100px + 80px); /* Account for chatbox height */
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Scrollbar Styling for WebKit Browsers */
.messagesContainer::-webkit-scrollbar {
  width: 8px;
}

.messagesContainer::-webkit-scrollbar-track {
  background: transparent;
}

.messagesContainer::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

.messagesContainer::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Individual Message Wrapper */
.messageWrapper {
  margin-bottom: 15px;
}

/* Message Container */
.message {
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Controls the width of individual messages */
  margin-bottom: 10px;
}

.message:focus {
  outline: none; /* Remove Unwanted Focus Outlines */
}

/* Message Types Alignment */
.messageUser {
  align-self: flex-end;
}

.messageBot,
.messageTask,
.messageSystem,
.messageCompletion,
.messageError {
  align-self: flex-start;
}

/* Message Text Styling */
.messageText {
  padding: 12px 16px;
  border-radius: 16px;
  background-color: var(--message-bg);
  color: var(--text-color);
  word-wrap: break-word;
  overflow-wrap: break-word; /* Ensures long words break correctly */
  word-break: break-word; /* Forces long words to break */
  white-space: normal; /* Allows text to wrap */
  line-height: 1.5;
  max-width: 100%; /* Ensures message bubbles don't exceed their container */
  box-sizing: border-box; /* Includes padding in width calculations */
}

/* Markdown Content Styling */
.messageText h1,
.messageText h2,
.messageText h3,
.messageText h4,
.messageText h5,
.messageText h6 {
  color: var(--text-color);
  margin: 0.5em 0;
}

.messageText p {
  margin: 0.5em 0;
  color: var(--text-color);
}

.messageText ul,
.messageText ol {
  margin: 0.5em 0;
  padding-left: 1.5em;
}

.messageText li {
  margin: 0.25em 0;
}

.messageText strong {
  font-weight: bold;
}

.messageText em {
  font-style: italic;
}

.messageText code {
  background-color: #2d2d2d;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
}

.messageText pre {
  background-color: #2d2d2d;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.messageText pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

.messageText blockquote {
  border-left: 4px solid #555;
  padding-left: 10px;
  color: #aaa;
}

.messageText hr {
  border: none;
  border-top: 1px solid #555;
  margin: 1em 0;
}

/* Specific Message Type Styling */
.messageUser .messageText {
  background-color: var(--user-message-bg);
  border-bottom-right-radius: 0; /* Optional for ChatGPT style */
}

.messageBot .messageText {
  background-color: var(--bot-message-bg);
  border-bottom-left-radius: 0; /* Optional for ChatGPT style */
}

.messageTask .messageText {
  background-color: var(--task-message-bg);
  border-bottom-left-radius: 0; /* Optional styling */
}

.messageSystem .messageText {
  background-color: var(--system-message-bg);
  border-bottom-left-radius: 0; /* Optional styling */
  font-style: italic; /* Distinguish system messages */
}

.messageCompletion .messageText {
  background-color: var(--completion-message-bg); /* Uses the updated grey variable */
  color: var(--text-color); /* Ensures text is readable against grey background */
  border-bottom-left-radius: 0; /* Optional styling */
  font-weight: bold; /* Highlight completion messages */
}

.messageError .messageText {
  background-color: var(--error-message-bg);
  border-bottom-left-radius: 0; /* Optional styling */
  color: var(--error-text-color); /* Distinct text color for errors */
}

/* Message Timestamp Styling */
.messageTimestamp {
  font-size: 0.7rem;
  color: var(--text-color-light);
  margin-left: auto;
  margin-top: 5px; /* Additional space above the timestamp */
}

/* Prevent Display of PING Messages */
.messagePing {
  display: none;
}

/* Spinner Styling */
.spinner {
  font-size: 1.2rem; /* Adjust size as needed */
  color: var(--link-color); /* Adjust color */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Checkmark Styling for Completed Tasks */
.checkmark {
  font-size: 1.2rem; /* Match the size of the spinner */
  color: green; /* Use green to indicate success */
}

/* Processing Dropdown */
.processingDropdown {
  margin-left: 20px;
  margin-top: 10px;
  min-height: 150px;
  min-width: 300px; /* Added minimum width for a wider dropdown */
  position: relative;
}

/* Dropdown Summary */
.dropdownSummary {
  cursor: pointer;
  color: var(--link-color);
  font-size: 0.9rem;
  user-select: none;
  list-style: none;
  display: flex;
  align-items: center;
}

.dropdownSummary:hover {
  text-decoration: underline;
}

.dropdownSummary::after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

/* Dropdown Open State */
.processingDropdown.open .dropdownSummary::after {
  transform: rotate(180deg); /* Rotate arrow when open */
}

/* Dropdown Icon */
.dropdownIcon {
  font-size: 0.8rem;
  margin-left: 5px;
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

/* Processing Steps Container */
.processingSteps {
  padding: 15px 20px; /* Increased padding for spacing */
  background-color: var(--secondary-bg);
  border-radius: 5px;
  margin-top: 10px; /* Increased margin for more spacing */
  min-height: 100px; /* Prevent "scrunched" text */
  transition: min-height 0.3s ease; /* Smooth transition as content is added */
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 500px;
  }
}

/* Individual Processing Step */
.processingStep {
  margin-bottom: 12px; /* Increased margin between steps */
  display: flex;
  align-items: center;
}

/* Processing Step Text */
.stepText {
  font-size: 0.9rem; /* Slightly larger for readability */
  color: var(--text-color);
}

/* Processing Step Timestamp */
.stepTimestamp {
  font-size: 0.7rem;
  color: var(--text-color-light);
  margin-left: auto;
  margin-top: 5px; /* Additional space above the timestamp */
}

/* Loading and Error States */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loadingSpinner {
  font-size: 1rem;
  color: var(--text-color);
}

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorMessage {
  color: red;
  margin-bottom: 10px;
}

.retryButton {
  padding: 8px 16px;
  background-color: rgb(59, 130, 246);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.retryButton:hover {
  background-color: rgb(37, 99, 235);
}
