/* ===========================
   animations.css
   ===========================
   Central place for keyframes & optional utility classes
*/

/* Spin */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade In (common) */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade In Up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Pulse */
@keyframes pulse {
  0%, 100% { 
    transform: scale(1); 
    opacity: 1; 
  }
  50% { 
    transform: scale(1.1); 
    opacity: 0.8; 
  }
}

/* Slide Up */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Color Shift Example */
@keyframes colorShift {
  0%, 100% { filter: hue-rotate(0deg); }
  50% { filter: hue-rotate(45deg); }
}

/* "Glitch" effect (example) */
@keyframes glitch-anim {
  0% { clip: rect(10px, 9999px, 76px, 0); }
  5% { clip: rect(84px, 9999px, 42px, 0); }
  10% { clip: rect(63px, 9999px, 4px, 0); }
  15% { clip: rect(54px, 9999px, 39px, 0); }
  /* Additional steps as needed */
  100% { clip: rect(29px, 9999px, 84px, 0); }
}

@keyframes glitch-anim2 {
  /* Second glitch pass - define similarly if needed */
}

/* Float / bobbing effect */
@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

/* Sparkle effect for icons */
@keyframes sparkle {
  0% { opacity: 0.8; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.1); }
  100% { opacity: 0.8; transform: scale(1); }
}

/* -----------------------------
   Optional Utility Classes
------------------------------ */
.spinInfinite {
  animation: spin 1s linear infinite;
}

.fadeInUp {
  animation: fadeInUp 0.5s ease-out forwards;
}

.pulseInfinite {
  animation: pulse 2s ease-in-out infinite;
}

.slideUp {
  animation: slideUp 0.5s ease-out forwards;
}
