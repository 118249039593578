/* ===========================
   modals.css
   ===========================
   Shared modal overlay & container styles
*/

/* Overlay covers the entire viewport */
.modalOverlay {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-modal);
  animation: fadeIn 0.2s ease; /* see animations.css or inline keyframes */
  backdrop-filter: blur(2px);
}

/* Modal container */
.modal {
  background-color: var(--secondary-bg);
  border-radius: var(--radius-lg);
  padding: var(--space-lg);
  width: 100%;
  max-width: var(--modal-width-md);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24),
              0 2px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: slideUp 0.2s ease;  /* see animations.css */
  color: var(--text-color-light);
  position: relative;
  margin: var(--space-md);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

/* Size variants if needed */
.modal.small { max-width: var(--modal-width-sm); }
.modal.medium { max-width: var(--modal-width-md); }
.modal.large { max-width: var(--modal-width-lg); }

/* Header styling */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-md);
  position: sticky;
  top: 0;
  background-color: var(--secondary-bg);
  z-index: 1;
  padding-bottom: var(--space-sm);
}

.modalTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-color);
}

/* Close button */
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease;
  width: 32px; height: 32px;
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center; 
  justify-content: center;
  padding: 0;
}
.closeButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}
.closeButton:focus { outline: none; box-shadow: var(--focus-ring); }

/* Modal body */
.modalBody {
  margin-bottom: var(--space-md);
  font-size: var(--font-size-sm);
  color: var(--text-color-light);
  line-height: var(--line-height-relaxed);
}

/* Footer for actions */
.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: var(--space-sm);
  margin-top: var(--space-lg);
  padding-top: var(--space-sm);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  bottom: 0;
  background-color: var(--secondary-bg);
}

/* Scrollbar styling in the modal */
.modal::-webkit-scrollbar {
  width: 6px;
}
.modal::-webkit-scrollbar-track {
  background: transparent;
}
.modal::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}
.modal::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* If you want a fadeIn & slideUp quickly: 
   define them in animations.css or inline:
*/
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: var(--breakpoint-mobile)) {
  .modal {
    margin: var(--space-sm);
    max-height: calc(100vh - var(--space-md)*2);
  }
  .modalFooter {
    flex-direction: column-reverse;
  }
  .modalFooter button {
    width: 100%;
  }
}
