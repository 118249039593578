/* src/components/Footer/Footer.module.css */
.footer {
  padding: 20px;
  background-color: #1a1a1a;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footerMainBox,
.footerBottomBox {
  width: 100%;
  max-width: 1200px;
  background-color: #242424;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.footerSections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.footerColumn {
  flex: 1;
  min-width: 200px;
}

.footerColumn h4 {
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  padding-bottom: 8px;
  border-bottom: 2px solid #00a86b;
}

.footerColumn ul {
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
}

.footerColumn ul li {
  margin-bottom: 12px;
}

.footerColumn ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.footerColumn ul li a:hover {
  color: #00a86b;
  transform: translateX(2px);
  display: inline-block;
}

.footerBottomBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px;
  gap: 20px;
}

.footerBottomBox p {
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.socialIcons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.socialIcons a {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.socialIcon {
  font-size: 24px;
  transition: all 0.2s ease;
}

.socialIcons a:hover {
  color: #00a86b;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .footerSections {
    gap: 30px;
  }

  .footerColumn {
    min-width: 160px;
  }

  .footerBottomBox {
    flex-direction: column;
    text-align: center;
  }

  .socialIcons {
    justify-content: center;
  }
}
