/* src/components/TaskStatusDropdown/TaskStatusDropdown.module.css */

/* TaskStatusDropdown.module.css */
.dropdownContainer {
  position: relative;
  min-height: 32px;
  color: rgb(172, 172, 190);
  margin: 6px 0;
  transition: color 0.2s ease;
}

.dropdownContainer:hover {
  color: rgb(217, 217, 227);
}

.dropdownWrapper {
  position: relative;
  margin-right: 6px;
  height: 32px;
  margin-top: 4px;
}

.dropdownSummary {
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  transition: all 0.2s ease;
  user-select: none;
}

.clockIcon {
  width: 16px;
  height: 16px;
}

.checkIcon {
  width: 16px;
  height: 16px;
  color: rgb(0, 168, 107);
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdownIcon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
  margin-left: 4px;
}

.dropdownIcon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
}

.processingSteps {
  margin-top: 8px;
}

.timelineContainer {
  border-left: 2px solid rgb(86, 88, 105);
  padding-left: 16px;
  margin-bottom: 16px;
}

.processingStep {
  margin-bottom: 16px;
}

.processingStep:last-child {
  margin-bottom: 0;
}

.stepContent {
  color: rgb(217, 217, 227);
}

.stepText {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(172, 172, 190);
}

.stepTimestamp {
  font-size: 0.75rem;
  color: rgb(142, 142, 160);
  margin-top: 4px;
}

/* Dark theme adjustments */
:global(.dark) .dropdownContainer {
  color: rgb(172, 172, 190);
}

:global(.dark) .dropdownContainer:hover {
  color: rgb(217, 217, 227);
}

:global(.dark) .timelineContainer {
  border-left-color: rgb(86, 88, 105);
}

:global(.dark) .stepText {
  color: rgb(172, 172, 190);
}

:global(.dark) .stepTimestamp {
  color: rgb(142, 142, 160);
}