/* NotificationModal.module.css */
.content {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;
}

.icon {
  font-size: 24px;
  flex-shrink: 0;
}

.icon.success {
  color: #00a86b;
}

.icon.error {
  color: #dc3545;
}

.message {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  font-size: 0.95rem;
}

.button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
}

.button.success {
  background-color: #00a86b;
  color: white;
}

.button.success:hover {
  background-color: #008f5d;
}

.button.error {
  background-color: #dc3545;
  color: white;
}

.button.error:hover {
  background-color: #c82333;
}