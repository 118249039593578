/* src/components/common/Button/Button.module.css */

.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--button-text-color, #fff); /* Default to white */
    background-color: var(--button-bg-color, #007bff); /* Default to blue */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button:hover {
    background-color: var(--button-hover-bg-color, #0056b3); /* Default to darker blue */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button:active {
    background-color: var(--button-active-bg-color, #004085); /* Even darker blue */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button:disabled {
    background-color: var(--button-disabled-bg-color, #6c757d); /* Default to grey */
    cursor: not-allowed;
    opacity: 0.65;
}

.button-primary {
    background-color: var(--button-primary-bg-color, #007bff);
    color: var(--button-primary-text-color, #fff);
}

.button-secondary {
    background-color: var(--button-secondary-bg-color, #6c757d);
    color: var(--button-secondary-text-color, #fff);
}

.button-outline {
    background-color: transparent;
    color: var(--button-outline-color, #007bff);
    border: 2px solid var(--button-outline-color, #007bff);
}

.button-outline:hover {
    background-color: var(--button-outline-hover-bg-color, #007bff);
    color: var(--button-outline-hover-text-color, #fff);
}
