/* src/components/common/Modal/Modal.module.css */

/* Base Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
  backdrop-filter: blur(2px); /* Add subtle blur effect */
}

.modal {
  background-color: #242424;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.24), 
              0 2px 8px rgba(0, 0, 0, 0.12); /* Enhanced shadow */
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: slideUp 0.2s ease;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  margin: 20px;
  max-height: calc(100vh - 40px); /* Prevent overflow */
  overflow-y: auto; /* Enable scrolling if content is too long */
}

/* Modal sizes with max-height */
.small {
  max-width: 400px;
}

.medium {
  max-width: 600px;
}

.large {
  max-width: 800px;
}

/* Header Styling */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: sticky; /* Keep header visible when scrolling */
  top: 0;
  background-color: #242424;
  padding-bottom: 12px;
  z-index: 1;
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

/* Close Button */
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s ease;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: -8px -8px 0 0;
}

.closeButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.closeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

/* Body and Footer */
.modalBody {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  font-size: 0.95rem;
  margin-bottom: 20px; /* Space before footer */
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky; /* Keep footer visible when scrolling */
  bottom: 0;
  background-color: #242424;
  z-index: 1;
}

/* Scrollbar Styling */
.modal::-webkit-scrollbar {
  width: 6px;
}

.modal::-webkit-scrollbar-track {
  background: transparent;
}

.modal::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal {
    margin: 16px;
    padding: 20px;
    max-height: calc(100vh - 32px);
  }

  .modalFooter {
    flex-direction: column-reverse;
    padding-bottom: 8px;
  }

  .modalFooter button {
    width: 100%;
  }

  .modalHeader {
    padding-top: 8px;
  }
}

/* Focus Management */
.modal:focus {
  outline: none;
}

/* Utility for long content */
.scrollLock {
  overflow: hidden;
}