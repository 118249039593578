/* ===========================
   cards.css
   ===========================
   Common .card styles plus variants
*/

/* Base card pattern */
.card {
  background: var(--secondary-bg);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: var(--space-lg);
  transition: all 0.3s ease;
  position: relative;
}

/* Hover or focus states */
.card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
  border-color: var(--link-color);
}

/* If you have a �highlight� or �popular� version */
.card--highlight {
  border: 2px solid var(--accent-blue);
  background: linear-gradient(145deg, rgba(59, 130, 246, 0.1), transparent);
}

/* Example heading in a card */
.cardTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-color);
  margin-bottom: var(--space-md);
}

/* Example body text */
.cardBody {
  font-size: var(--font-size-md);
  color: var(--text-color-light);
  line-height: var(--line-height-relaxed);
}

/* Icon at the top of the card, if you wish */
.cardIcon {
  font-size: 2rem;
  color: var(--link-color);
  margin-bottom: var(--space-md);
}

/* For small "badge" or "ribbon" */
.cardBadge {
  position: absolute;
  top: var(--space-sm);
  right: -3rem;
  transform: rotate(45deg);
  padding: 0.5rem 2.5rem;
  background: var(--accent-blue);
  color: #fff;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
}

/* Grid layout if you want .card within a .cardsGrid */
.cardsGrid {
  display: grid;
  gap: var(--space-2xl);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: var(--container-max-width);
  margin: 0 auto;
}
