.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-bg);
    padding: 10px 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: top 0.3s ease;
}

.headerHidden {
    top: -100px;
}

.logo {
    display: flex;
    align-items: center;
}

.logoImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav {
    display: flex;
    gap: 20px;
    flex-grow: 1;
    justify-content: center;
}

.navList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
}

.navLink {
    color: var(--text-color);
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 10px 15px;
    border-radius: 5px;
}

.navLink:hover,
.navLink:focus {
    color: var(--link-color);
    background-color: rgba(255, 255, 255, 0.1);
}

.authButtons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.loginSignupButton {
    padding: 10px 20px;
    background-color: var(--link-color);
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.dashboardButton {
    background-color: var(--text-color);
    color: var(--link-color);
}

.dashboardButton:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
}

.logoutButton {
    background-color: transparent;
    border: 1px solid var(--text-color);
}

.logoutButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.loginSignupButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
    }

    .nav {
        width: 100%;
        margin: 10px 0;
    }

    .navList {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .navLink {
        display: block;
        width: 100%;
        padding: 10px;
    }

    .authButtons {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }

    .loginSignupButton {
        width: 100%;
    }
}

/* Animation for button hover */
@keyframes buttonHover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-2px);
    }
}

/* Dark mode enhancements */
@media (prefers-color-scheme: dark) {
    .dashboardButton {
        background-color: var(--text-color);
        color: var(--secondary-bg);
    }

    .logoutButton:hover {
        border-color: var(--link-color);
        color: var(--link-color);
    }
}