/* src/pages/DashboardPage/Dashboard/ChatBox/ChatBox.module.css */

/* Chatbox should remain fixed at the bottom */
.chatboxContainer {
  position: fixed;
  bottom: 0;
  left: calc(50% + 140px); /* 280px/2 = 140px to offset from sidebar */
  transform: translateX(-50%);
  width: calc(100% - 280px); /* Subtract sidebar width */
  max-width: 800px;
  padding: 15px 20px;
  border-radius: 10px;
  z-index: 1000;
  background-color: var(--chatbox-bg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

/* Add collapsed sidebar state */
.sidebarCollapsed ~ .chatboxContainer {
  left: calc(50% + 36px); /* 72px/2 = 36px when sidebar is collapsed */
  width: calc(100% - 72px); /* Subtract collapsed sidebar width */
}

/* Update mobile styles */
@media (max-width: 768px) {
  .chatboxContainer {
    left: 50%; /* Reset to center when sidebar is hidden */
    width: 95%; /* Full width minus margins */
    padding: 10px 15px;
    border-radius: 10px;
  }
}

/* Input Area */
.chatboxInputArea {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: var(--input-bg);
  border-top: 1px solid var(--border-color);
  width: 100%;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}

/* Attachment and Submit Button Styles */
.chatboxAttachmentButton,
.chatboxSubmitButton {
  background: none;
  border: none;
  color: #bbbbbb;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;
  transition: color 0.3s, transform 0.2s;
}

.chatboxAttachmentButton:hover,
.chatboxSubmitButton:hover {
  color: var(--link-color);
  transform: scale(1.1);
}

/* Textarea Styling */
.chatboxTextarea {
  flex: 1;
  resize: none;
  border: none;
  padding: 10px;
  font-size: 1rem;
  background-color: var(--input-bg);
  color: var(--text-color);
  max-height: 150px;
  overflow-y: auto;
  outline: none;
  line-height: 1.5;
  transition: height 0.2s ease;
  overflow-wrap: break-word; /* Ensures text wraps within textarea */
  white-space: normal;
}

/* Placeholder Text Color */
.chatboxTextarea::placeholder {
  color: #aaaaaa;
  opacity: 1; /* Firefox */
}

/* Error Message */
.chatboxError {
  color: #e74c3c; /* Matches --error-message-bg */
  margin-top: 10px;
}

/* Reconnecting Message */
.chatboxReconnecting {
  color: #ffa500; /* Orange color for reconnection */
  margin-top: 10px;
}

/* Locked State Message */
.chatboxLocked {
  color: var(--link-color);
  margin-top: 10px;
  font-size: 0.9rem;
}

/* Spinner Styling */
.spinner {
  font-size: 1.2rem; /* Adjust size as needed */
  color: var(--link-color); /* Adjust color */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Checkmark Styling */
.checkmark {
  font-size: 1.2rem; /* Match the size of the spinner */
  color: green; /* Use green to indicate success */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .chatboxContainer {
    width: 95%; /* Full width on smaller screens */
    padding: 10px 15px;
    border-radius: 10px;
  }

  .chatboxInputArea {
    padding: 8px 10px;
    border-radius: 0 0 8px 8px;
  }

  .chatboxTextarea {
    font-size: 0.9rem;
  }
}

/* Locked State Styling */
.locked {
  opacity: 0.8; /* Slightly dimmed to indicate lock */
  /* Optional: Add a cursor not-allowed */
  cursor: not-allowed;
}
