.primaryButton {
  padding: var(--space-md) var(--space-xl);
  border-radius: var(--radius-md);
  font-weight: var(--font-weight-semibold);
  transition: var(--transition-normal);
  display: inline-flex;
  align-items: center;
  gap: var(--space-sm);
  font-size: var(--font-size-lg);
  background: var(--gradient-primary);
  color: var(--text-color);
  text-decoration: none;
}

.primaryButton:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.buttonIcon {
  width: var(--space-md);
  height: var(--space-md);
  transition: transform var(--transition-normal);
}
