/* src/pages/DashboardPage/Dashboard/Settings/SettingsPage.module.css */

.settingsPage {
  display: flex;
  height: 100vh;
  background-color: #1a1a1a;
  color: rgba(255, 255, 255, 0.9);
}

/* Sidebar Styles */
.sidebar {
  width: 260px;
  background-color: #202020;
  padding: 2rem 1rem;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarTitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding: 0 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.sidebarNav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sidebarItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.sidebarItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  transform: translateX(2px);
}

.sidebarItem.active {
  background-color: rgba(0, 168, 107, 0.15);
  color: #00a86b;
}

/* Content Area */
.content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

/* Section Styling */
.settingSection {
  background-color: #242424;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sectionHeader h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.sectionIcon {
  font-size: 1.5rem;
  color: #00a86b;
}

.settingGroup {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.settingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.settingItem:hover {
  background-color: rgba(255, 255, 255, 0.08);
  transform: translateY(-1px);
}

.settingHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.settingIcon {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
}

.settingInfo h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: rgba(255, 255, 255, 0.9);
}

.settingInfo p {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

/* Switch Styling */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s ease;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.3s ease;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

input:checked + .slider {
  background-color: #00a86b;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

/* Select Styling */
.select {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
  padding: 0.75rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  min-width: 140px;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.select:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.select:focus {
  outline: none;
  border-color: #00a86b;
  box-shadow: 0 0 0 2px rgba(0, 168, 107, 0.2);
}

/* Button Styling */
.actionButton {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  min-width: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.actionButton:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.actionButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.archiveButton {
  background-color: #00a86b;
  color: white;
}

.archiveButton:hover:not(:disabled) {
  background-color: #008f5d;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
}

.deleteButton:hover:not(:disabled) {
  background-color: #c82333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .settingsPage {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 1rem;
  }

  .content {
    padding: 1rem;
  }

  .settingSection {
    padding: 1.5rem;
  }

  .settingItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .settingHeader {
    width: 100%;
  }

  .select {
    width: 100%;
  }

  .actionButton {
    width: 100%;
  }
}