/* ===========================
   utilities.css
   ===========================
   Centralized utility classes for spacing, typography,
   layout, backgrounds, text colors, borders, shadows,
   flexbox, grid, transitions, etc.
*/

/* ===========================
   SPACING UTILITIES
   (margin & padding)
   =========================== */

/* Margin Utilities */
.m-0 { margin: 0; }
.mt-0 { margin-top: 0; }
.mt-1 { margin-top: var(--space-xs); }
.mt-2 { margin-top: var(--space-sm); }
.mt-3 { margin-top: var(--space-md); }
.mt-4 { margin-top: var(--space-lg); }
.mt-5 { margin-top: var(--space-xl); }
.mt-6 { margin-top: var(--space-2xl); }
.mt-7 { margin-top: var(--space-3xl); }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: var(--space-xs); }
.mb-2 { margin-bottom: var(--space-sm); }
.mb-3 { margin-bottom: var(--space-md); }
.mb-4 { margin-bottom: var(--space-lg); }
.mb-5 { margin-bottom: var(--space-xl); }
.mb-6 { margin-bottom: var(--space-2xl); }
.mb-7 { margin-bottom: var(--space-3xl); }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: var(--space-xs); }
.ml-2 { margin-left: var(--space-sm); }
.ml-3 { margin-left: var(--space-md); }
.ml-4 { margin-left: var(--space-lg); }
.ml-5 { margin-left: var(--space-xl); }
.ml-6 { margin-left: var(--space-2xl); }
.ml-7 { margin-left: var(--space-3xl); }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: var(--space-xs); }
.mr-2 { margin-right: var(--space-sm); }
.mr-3 { margin-right: var(--space-md); }
.mr-4 { margin-right: var(--space-lg); }
.mr-5 { margin-right: var(--space-xl); }
.mr-6 { margin-right: var(--space-2xl); }
.mr-7 { margin-right: var(--space-3xl); }

.mx-0 { margin-left: 0; margin-right: 0; }
.mx-1 { margin-left: var(--space-xs); margin-right: var(--space-xs); }
.mx-2 { margin-left: var(--space-sm); margin-right: var(--space-sm); }
.mx-3 { margin-left: var(--space-md); margin-right: var(--space-md); }
.mx-4 { margin-left: var(--space-lg); margin-right: var(--space-lg); }
.mx-5 { margin-left: var(--space-xl); margin-right: var(--space-xl); }
.mx-6 { margin-left: var(--space-2xl); margin-right: var(--space-2xl); }
.mx-7 { margin-left: var(--space-3xl); margin-right: var(--space-3xl); }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: var(--space-xs); margin-bottom: var(--space-xs); }
.my-2 { margin-top: var(--space-sm); margin-bottom: var(--space-sm); }
.my-3 { margin-top: var(--space-md); margin-bottom: var(--space-md); }
.my-4 { margin-top: var(--space-lg); margin-bottom: var(--space-lg); }
.my-5 { margin-top: var(--space-xl); margin-bottom: var(--space-xl); }
.my-6 { margin-top: var(--space-2xl); margin-bottom: var(--space-2xl); }
.my-7 { margin-top: var(--space-3xl); margin-bottom: var(--space-3xl); }

/* Padding Utilities */
.p-0 { padding: 0; }
.pt-0 { padding-top: 0; }
.pt-1 { padding-top: var(--space-xs); }
.pt-2 { padding-top: var(--space-sm); }
.pt-3 { padding-top: var(--space-md); }
.pt-4 { padding-top: var(--space-lg); }
.pt-5 { padding-top: var(--space-xl); }
.pt-6 { padding-top: var(--space-2xl); }
.pt-7 { padding-top: var(--space-3xl); }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: var(--space-xs); }
.pb-2 { padding-bottom: var(--space-sm); }
.pb-3 { padding-bottom: var(--space-md); }
.pb-4 { padding-bottom: var(--space-lg); }
.pb-5 { padding-bottom: var(--space-xl); }
.pb-6 { padding-bottom: var(--space-2xl); }
.pb-7 { padding-bottom: var(--space-3xl); }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: var(--space-xs); }
.pl-2 { padding-left: var(--space-sm); }
.pl-3 { padding-left: var(--space-md); }
.pl-4 { padding-left: var(--space-lg); }
.pl-5 { padding-left: var(--space-xl); }
.pl-6 { padding-left: var(--space-2xl); }
.pl-7 { padding-left: var(--space-3xl); }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: var(--space-xs); }
.pr-2 { padding-right: var(--space-sm); }
.pr-3 { padding-right: var(--space-md); }
.pr-4 { padding-right: var(--space-lg); }
.pr-5 { padding-right: var(--space-xl); }
.pr-6 { padding-right: var(--space-2xl); }
.pr-7 { padding-right: var(--space-3xl); }

.px-0 { padding-left: 0; padding-right: 0; }
.px-1 { padding-left: var(--space-xs); padding-right: var(--space-xs); }
.px-2 { padding-left: var(--space-sm); padding-right: var(--space-sm); }
.px-3 { padding-left: var(--space-md); padding-right: var(--space-md); }
.px-4 { padding-left: var(--space-lg); padding-right: var(--space-lg); }
.px-5 { padding-left: var(--space-xl); padding-right: var(--space-xl); }
.px-6 { padding-left: var(--space-2xl); padding-right: var(--space-2xl); }
.px-7 { padding-left: var(--space-3xl); padding-right: var(--space-3xl); }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: var(--space-xs); padding-bottom: var(--space-xs); }
.py-2 { padding-top: var(--space-sm); padding-bottom: var(--space-sm); }
.py-3 { padding-top: var(--space-md); padding-bottom: var(--space-md); }
.py-4 { padding-top: var(--space-lg); padding-bottom: var(--space-lg); }
.py-5 { padding-top: var(--space-xl); padding-bottom: var(--space-xl); }
.py-6 { padding-top: var(--space-2xl); padding-bottom: var(--space-2xl); }
.py-7 { padding-top: var(--space-3xl); padding-bottom: var(--space-3xl); }

/* ===========================
   TYPOGRAPHY UTILITIES
   =========================== */

/* Font Size Utilities */
.text-xs { font-size: var(--font-size-xs); }
.text-sm { font-size: var(--font-size-sm); }
.text-md { font-size: var(--font-size-md); }
.text-lg { font-size: var(--font-size-lg); }
.text-xl { font-size: var(--font-size-xl); }
.text-2xl { font-size: var(--font-size-2xl); }
.text-3xl { font-size: var(--font-size-3xl); }
.text-4xl { font-size: var(--font-size-4xl); }
.text-5xl { font-size: var(--font-size-5xl); }

/* Font Weight Utilities */
.font-normal { font-weight: var(--font-weight-normal); }
.font-medium { font-weight: var(--font-weight-medium); }
.font-semibold { font-weight: var(--font-weight-semibold); }
.font-bold { font-weight: var(--font-weight-bold); }

/* Line Height Utilities */
.leading-tight { line-height: var(--line-height-tight); }
.leading-normal { line-height: var(--line-height-normal); }
.leading-relaxed { line-height: var(--line-height-relaxed); }
.leading-loose { line-height: var(--line-height-loose); }

/* Text Alignment */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

/* ===========================
   BORDER RADIUS UTILITIES
   =========================== */
.rounded-none { border-radius: 0; }
.rounded-xs { border-radius: var(--radius-xs); }
.rounded-sm { border-radius: var(--radius-sm); }
.rounded-md { border-radius: var(--radius-md); }
.rounded-lg { border-radius: var(--radius-lg); }
.rounded-xl { border-radius: var(--radius-xl); }
.rounded-full { border-radius: var(--radius-full); }

/* ===========================
   BOX SHADOW UTILITIES
   =========================== */
.shadow-none { box-shadow: none; }
.shadow-sm { box-shadow: var(--shadow-sm); }
.shadow-md { box-shadow: var(--shadow-md); }
.shadow-lg { box-shadow: var(--shadow-lg); }
.shadow-xl { box-shadow: var(--shadow-xl); }

/* ===========================
   TRANSITION UTILITIES
   =========================== */
.transition-fast { transition: var(--transition-fast); }
.transition-normal { transition: var(--transition-normal); }
.transition-slow { transition: var(--transition-slow); }
.transition-bounce { transition-timing-function: var(--transition-bounce); }

/* ===========================
   BACKGROUND COLOR UTILITIES
   =========================== */
.bg-primary { background-color: var(--primary-bg); }
.bg-secondary { background-color: var(--secondary-bg); }
.bg-tertiary { background-color: var(--tertiary-bg); }
.bg-transparent { background-color: transparent; }

/* Custom BG Colors */
.bg-chatbox { background-color: var(--chatbox-bg); }
.bg-select { background-color: var(--select-bg); }
.bg-sidebar { background-color: var(--sidebar-bg); }
.bg-badge { background-color: var(--badge-bg); }
.bg-error-message { background-color: var(--error-message-bg-light); }

/* ===========================
   TEXT COLOR UTILITIES
   =========================== */
.text-primary { color: var(--text-color); }
.text-light { color: var(--text-color-light); }
.text-lighter { color: var(--text-color-lighter); }
.text-dark { color: var(--text-color-dark); }
.text-link { color: var(--link-color); }
.text-link-hover:hover { color: var(--link-color-hover); }
.text-error-message { color: var(--error-message-text); }
.text-badge { color: var(--badge-text-color); }

/* ===========================
   BORDER COLOR UTILITIES
   =========================== */
.border { border: 1px solid var(--border-color); }
.border-light { border-color: rgba(255, 255, 255, 0.1); }
.border-select { border: var(--select-border); }
.border-sidebar { border-right: var(--sidebar-border-right); }
.border-error-message { border-color: var(--error-message-border); }

/* ===========================
   Z-INDEX UTILITIES
   =========================== */
.z-negative { z-index: var(--z-negative); }
.z-elevate { z-index: var(--z-elevate); }
.z-sticky { z-index: var(--z-sticky); }
.z-modal { z-index: var(--z-modal); }
.z-max { z-index: var(--z-max); }

/* ===========================
   FLEXBOX UTILITIES
   =========================== */
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }
.flex-row { flex-direction: row; }
.flex-col { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.align-start { align-items: flex-start; }
.align-center { align-items: center; }
.align-end { align-items: flex-end; }

/* ===========================
   GRID UTILITIES
   =========================== */
.d-grid { display: grid; }
.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }
.grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
.grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
.grid-auto-fit-minmax-300 { grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); }

/* ===========================
   GAP UTILITIES
   =========================== */
.gap-0 { gap: 0; }
.gap-1 { gap: var(--space-xs); }
.gap-2 { gap: var(--space-sm); }
.gap-3 { gap: var(--space-md); }
.gap-4 { gap: var(--space-lg); }
.gap-5 { gap: var(--space-xl); }
.gap-6 { gap: var(--space-2xl); }

/* ===========================
   HOVER EFFECT UTILITIES
   =========================== */
.hover\:shadow-lg:hover { box-shadow: var(--shadow-lg); }
.hover\:scale:hover { transform: scale(var(--hover-scale)); }
.hover\:brightness:hover { filter: brightness(var(--hover-brightness)); }

/* ===========================
   FOCUS STATE UTILITIES
   =========================== */
.focus\:ring:focus { box-shadow: var(--focus-ring); }
.focus\:ring-offset:focus { box-shadow: var(--focus-ring-offset); }

/* ===========================
   VISIBILITY UTILITIES
   =========================== */
.hidden { display: none !important; }
.block { display: block !important; }
.inline-block { display: inline-block !important; }
.inline { display: inline !important; }

/* ===========================
   CONTAINER UTILITY
   =========================== */
.container {
  width: 90%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 var(--space-md);
}

/* ===========================
   SCROLL & OVERFLOW UTILS
   =========================== */
.overflow-auto { overflow: auto; }
.overflow-hidden { overflow: hidden; }
.overflow-scroll { overflow: scroll; }

/* ===========================
   MISCELLANEOUS
   =========================== */

/* Use these for quick text transforms, if needed */
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }

/* Utility to center content vertically/horizontally on screen */
.center-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* ===========================
   RESPONSIVE UTILITIES
   (examples)
   =========================== */

/* Hide on mobile */
@media (max-width: var(--breakpoint-mobile)) {
  .hidden-mobile { display: none !important; }
}

/* Hide on tablet */
@media (max-width: var(--breakpoint-tablet)) {
  .hidden-tablet { display: none !important; }
}

/* Show only on desktop */
@media (min-width: var(--breakpoint-desktop)) {
  .desktop-only { display: block !important; }
}
