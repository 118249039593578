/* ConfirmationModal.module.css */
.message {
  margin: 0 0 16px 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  font-size: 0.95rem;
}

.cancelButton {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
}

.cancelButton:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.15);
}

.confirmButton {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  color: white;
}

.confirmButton.default {
  background-color: #0d6efd;
}

.confirmButton.default:hover:not(:disabled) {
  background-color: #0b5ed7;
}

.confirmButton.danger {
  background-color: #dc3545;
}

.confirmButton.danger:hover:not(:disabled) {
  background-color: #c82333;
}

.confirmButton.warning {
  background-color: #ffc107;
  color: #000;
}

.confirmButton.warning:hover:not(:disabled) {
  background-color: #e0a800;
}

.confirmButton.success {
  background-color: #00a86b;
}

.confirmButton.success:hover:not(:disabled) {
  background-color: #008f5d;
}

.confirmButton:disabled,
.cancelButton:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
