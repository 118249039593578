.integrationsPage {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-color);
}

.pageTitle {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--primary-color);
}

.integrationGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
}

.integrationCard {
  background-color: var(--secondary-bg);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
}

.integrationCard:hover {
  transform: translateY(-5px);
}

.integrationIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.integrationName {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.connectionButton {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connected {
  background-color: var(--success-color);
  color: white;
}

.disconnected {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.connectionButton:hover {
  opacity: 0.9;
}