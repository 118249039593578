/* ===========================
   global.css
   =========================== */

/* -------------------------------------------
   1) Imports (optional if you handle them elsewhere)
   ------------------------------------------- */
@import './variables.css';
@import './utilities.css';
@import './hero.css';
@import './animations.css';
@import './modals.css';
@import './cards.css';
@import './forms.css';
@import './Button.css';


/* -------------------------------------------
   2) CSS Reset and Base Styles
   ------------------------------------------- */

/* Minimal reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%;
  font: inherit;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, nav, section {
  display: block;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Set default box-sizing and base sizing */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /* You can also set font-size here if you prefer a "root" size approach */
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--primary-bg);
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-md);
  line-height: var(--line-height-normal);
  overflow-x: hidden; /* Prevent horizontal scroll bars */
}

/* -------------------------------------------
   3) App Layout Helpers
   ------------------------------------------- */
.App {
  min-height: 100vh;
  background-color: var(--primary-bg);
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  overflow: visible;
  padding-top: var(--header-height); /* If you have a fixed header */
}

main {
  width: 100%;
  flex: 1;
}

/* -------------------------------------------
   4) Links & Buttons
   ------------------------------------------- */
a {
  color: var(--link-color);
  text-decoration: none;
  transition: color var(--transition-fast);
}
a:hover {
  color: var(--link-color-hover);
  text-decoration: underline;
}

/* Basic button reset */
button {
  font-family: var(--font-family);
  font-size: var(--font-size-md);
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

/* Focus ring for keyboard accessibility */
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* -------------------------------------------
   5) Images
   ------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* -------------------------------------------
   6) Selection Styles
   ------------------------------------------- */
::selection {
  background: var(--selection-bg);
  color: var(--selection-color);
}

::-moz-selection {
  background: var(--selection-bg);
  color: var(--selection-color);
}

/* -------------------------------------------
   7) Scrollbars (Optional)
   ------------------------------------------- */
/* Example scroll styling for webkit browsers:
   Use only if you want custom scrollbars. */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

/* -------------------------------------------
   8) Additional Global / Shared Classes
   ------------------------------------------- */

/* Slick carousel classes are in carousel.css, but if needed, add more global overrides here */

/* If you have repeating 'loading spinner center' patterns or global classes,
   you can add them below as well.
*/

/* Example spinner center */
.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
