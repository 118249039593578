/* ===========================
   hero.css
   ===========================
   Reusable hero section styles
*/

/* Base Hero Container
   Feel free to rename .hero to .heroSection or similar.
*/
.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Full height, or adjust to your needs */
  height: calc(100vh - var(--header-height));
  width: 100%;
  background-image: var(--gradient-dark);
  background-color: var(--primary-bg);
  overflow: hidden;
}

/* Dark overlay if you want that effect */
.hero::after {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: var(--overlay-dark);
  z-index: var(--z-below);
}

/* Inner hero content container */
.heroContent {
  position: relative;
  z-index: var(--z-above);
  text-align: center;
  padding: var(--space-lg);
  max-width: var(--container-max-width);
  width: 100%;
  margin: 0 auto;
}

/* Title text inside hero */
.heroTitle {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-md);
  line-height: var(--line-height-tight);
  text-shadow: var(--hero-text-shadow, 2px 2px 4px rgba(0,0,0,0.5));
  /* If you want a gradient text: 
     background: var(--gradient-primary); 
     -webkit-background-clip: text; 
     color: transparent;
  */
}

/* Subtitle or short description below title */
.heroSubtitle {
  font-size: var(--font-size-xl);
  color: var(--text-color-light);
  margin-bottom: var(--space-xl);
  text-shadow: var(--hero-text-shadow, 2px 2px 4px rgba(0,0,0,0.5));
  line-height: var(--line-height-relaxed);
}

/* Example button inside hero */
.heroButton {
  padding: var(--space-sm) var(--space-lg);
  background-color: var(--button-primary);
  color: var(--text-color);
  border-radius: var(--radius-md);
  font-weight: var(--font-weight-medium);
  transition: var(--button-transition);
}
.heroButton:hover {
  background-color: var(--button-primary-hover);
  transform: scale(var(--hero-button-hover-scale, 1.05));
  box-shadow: var(--hero-button-shadow, 0 4px 8px rgba(0,0,0,0.3));
}

/* Responsive Adjustments */
@media (max-width: var(--breakpoint-tablet)) {
  .hero {
    height: calc(70vh - var(--header-height));
  }
  .heroTitle {
    font-size: var(--font-size-3xl);
  }
  .heroSubtitle {
    font-size: var(--font-size-lg);
  }
}
