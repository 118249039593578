/* ===========================
   forms.css
   ===========================
   Shared form element styles (inputs, labels, etc.)
*/

/* Basic form grouping */
.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-md);
}

/* Label styling */
.formLabel {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-color);
  margin-bottom: var(--space-xs);
}

/* Input styling */
.formInput,
.textareaInput,
.selectInput {
  width: 100%;
  padding: var(--space-sm) var(--space-md);
  border-radius: var(--radius-md);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  font-size: var(--font-size-md);
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.formInput:focus,
.textareaInput:focus,
.selectInput:focus {
  outline: none;
  border-color: var(--brand-green, #00a86b);
  box-shadow: 0 0 0 2px rgba(0, 168, 107, 0.2);
}

/* Textarea if you want it */
.textareaInput {
  min-height: 100px;
  resize: vertical; /* user can resize */
}

/* Select if you want a custom style */
.selectInput {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg ... %3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px;
}

/* Error styling */
.formError {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  color: #ff6b6b; /* or var(--error-message-text) */
  padding: var(--space-sm);
  border-radius: var(--radius-sm);
  margin-bottom: var(--space-md);
  font-size: var(--font-size-sm);
}

/* Disabled state */
.formInput[disabled],
.selectInput[disabled],
.textareaInput[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Submit / action button for forms (as an example) */
.formButton {
  padding: var(--space-sm) var(--space-md);
  border-radius: var(--radius-md);
  border: none;
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  text-align: center;
}

/* Possibly define some color states for the button */
.formButton-primary {
  background: var(--brand-green, #00a86b);
  color: #fff;
}
.formButton-primary:hover:not(:disabled) {
  background: var(--brand-green-hover, #008f5d);
}
.formButton-secondary {
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.formButton-secondary:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.15);
}

.formButton:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
